@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fafbfc;
  &.modal-active {
    overflow: hidden;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Mui-selected {
  background-color: 'red';
  color: #fafbfc;
}

html{
  overflow: scroll;
}

.flip-card {
  background-color: transparent;
  width: 22rem;
  height: 16rem;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  border-radius: "1rem";
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front{
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
} 

.flip-card-back {
  position: absolute;
  width: 100%;
  height: 92%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: #bbb;
  color: black;
}

.flip-card-back {
  background-color: #2980b9;
  color: white;
  transform: rotateY(180deg);
}

.slide-in-fwd-right {
	animation: slide-in-fwd-right 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes slide-in-fwd-right {
  0% {
    transform: translateZ(-1400px) translateX(1000px);
    opacity: 0;
  }
  100% {
    transform: translateZ(0) translateX(0);
    opacity: 1;
  }
}

.slide-in-fwd-left {
	animation: slide-in-fwd-left 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes slide-in-fwd-left {
  0% {
    transform: translateZ(-1400px) translateX(-1000px);
    opacity: 0;
  }
  100% {
    transform: translateZ(0) translateX(0);
    opacity: 1;
  }
}

html .modal-active, body .modal-active {
  overflow: hidden;
}
#modal-container {
  position: fixed;
  display: table;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  transform: scale(0);
  z-index: 100;
}
#modal-container.four {
  z-index: 100;
  transform: scale(1);
}
#modal-container.four .modal-background {
  background: rgba(0, 0, 0, .7);
}
#modal-container.four .modal-background .modal {
  animation: blowUpModal 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}
#modal-container.four + .content {
  z-index: 100;
  animation: blowUpContent 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}
#modal-container.four.out .modal-background .modal {
  animation: blowUpModalTwo 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}
#modal-container.four.out + .content {
  animation: blowUpContentTwo 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}
#modal-container .modal-background {
  display: table-cell;
  background: rgba(0, 0, 0, .8);
  text-align: center;
  vertical-align: middle;
}
#modal-container .modal-background .modal {
  background: white;
  display: inline-block;
  border-radius: 1rem;
  font-weight: 300;
  position: relative;
}
#modal-container .modal-background .modal h2 {
  font-size: 25px;
  line-height: 25px;
  margin-bottom: 15px;
}
#modal-container .modal-background .modal p {
  font-size: 18px;
  line-height: 22px;
}
#modal-container .modal-background .modal .modal-svg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 3px;
}
#modal-container .modal-background .modal .modal-svg rect {
  stroke: #fff;
  stroke-width: 2px;
  stroke-dasharray: 778;
  stroke-dashoffset: 778;
}
@keyframes blowUpContent {
  0% {
    transform: scale(1);
    opacity: 1;
 }
  99.9% {
    transform: scale(2);
    opacity: 0;
 }
  100% {
    transform: scale(0);
 }
}
@keyframes blowUpContentTwo {
  0% {
    transform: scale(2);
    opacity: 0;
 }
  100% {
    transform: scale(1);
    opacity: 1;
 }
}
@keyframes blowUpModal {
  0% {
    transform: scale(0);
 }
  100% {
    transform: scale(1);
 }
}
@keyframes blowUpModalTwo {
  0% {
    transform: scale(1);
    opacity: 1;
 }
  100% {
    transform: scale(0);
    opacity: 0;
 }
}
@keyframes modalFadeIn {
  0% {
    background-color: transparent;
 }
  100% {
    background-color: white;
 }
}
@keyframes modalFadeOut {
  0% {
    background-color: white;
 }
  100% {
    background-color: transparent;
 }
}
@keyframes modalContentFadeIn {
  0% {
    opacity: 0;
    top: -20px;
 }
  100% {
    opacity: 1;
    top: 0;
 }
}
@keyframes modalContentFadeOut {
  0% {
    opacity: 1;
    top: 0px;
 }
  100% {
    opacity: 0;
    top: -20px;
 }
}

.fade-in-bl {
	animation: fade-in-bl 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation-delay: 1.5s;
}

@keyframes fade-in-bl {
  0% {
    transform: translateX(-50px) translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0) translateY(0);
    opacity: 1;
  }
}

@keyframes fade-in-fwd {
  0% {
    transform: translateZ(-80px);
    opacity: 0;
  }
  100% {
    transform: translateZ(0);
    opacity: 1;
  }
}

.hero1 {
	animation: fade-in-fwd 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation-delay: 0.2s;
}

.hero2 {
	animation: fade-in-fwd 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation-delay: 0.4s;
}

.hero3 {
	animation: fade-in-fwd 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation-delay: 0.6s;
}

.hero4 {
	animation: fade-in-fwd 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation-delay: 0.8s;
}

.hero5 {
	animation: fade-in-fwd 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation-delay: 1s;
}

